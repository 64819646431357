@font-face {
    font-family: 'Assistant';
    src: url('./fonts/Assistant-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('./fonts/Assistant-SemiBold.otf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('./fonts/Assistant-Bold.otf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('./fonts/Assistant-ExtraBold.otf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('./fonts/Assistant-Light.otf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('./fonts/Assistant-ExtraLight.otf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'NeueMontreal';
    src: url('./fonts/PPNeueMontreal-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NeueMontreal';
    src: url('./fonts/PPNeueMontreal-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NeueMontreal-Mono';
    src: url('./fonts/PPNeueMontrealMono-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'NeueMontreal', sans-serif;
    color: '#3F3F46';
}
